
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Company from "@/types/company";
import Partnership from "@/types/partnership";
import Modal from "@/components/Modal.vue";
import Group from "@/types/group";
import CompanyService, { StatusHistory } from "@/services/company-service";
import CreditPolicyService from "@/services/credit-policy-service";
import MarginBaseService, {
  ConsignetPartnership
} from "@/services/margin-base-service";
import EletronicSignatureService from "@/services/eletronic-signature-service";
import CompanyStatus from "@/components/companies/CompanyStatus.vue";
import { mask } from "vue-the-mask";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import municipios from "@/data/municipios";
import { Segments } from "@/data/segmentos";
import { SegmentsAvailableForSelfRegister } from "@/data/segmentos";
import CompanyDocument from "@/types/company-document";
import CompanyDocumentType from "@/types/company-document-type";
import CreditPolicy from "@/types/credit-policy";
import CompanyObservation from "../../types/company-observation";
import DatePicker from "@/components/DatePicker.vue";
import dayjs from "dayjs";
import LoanService from "@/services/loan-service";
import getErrorMessageForLandlineOrMobilePhoneValidation from "@/utils/getErrorMessageForLandlineOrMobilePhoneValidation";
import Products from "@/types/products";
import { DataTableHeader } from "vuetify";
import { ProductsTypeEnum } from "@/types/ProductsTypeEnum";
import { BankingEnum } from "@/types/BankingEnum";
import { AdministratorEnum } from "@/types/AdministratorEnum";
import { FundsEnum } from "@/types/FundsEnum";
import { format as formatCNPJ } from "@/utils/cnpj";
import { MaxContractsActivatedEnum } from "@/types/MaxContractsActivatedEnum";

type CreditPolicyItem = CreditPolicy & { computedName: string };

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal,
    CompanyStatus,
    DatePicker
  },
  directives: { mask },
  computed: {
    canManagePartnershipParameters(): boolean {
      return (
        (this as any).isAdminGooroo &&
        (this as any).hasPermissions(["EDITAR_DATAS_CONVENIO_EMPRESA"])
      );
    },

    formattedNextBatchGeneration(): string {
      const payCutDay = Number((this as any).item.partnership.payCutDay);
      let nextPayCutDate = dayjs().set("date", payCutDay);
      if (
        nextPayCutDate.isBefore(dayjs(), "date") ||
        nextPayCutDate.isSame(dayjs(), "date")
      ) {
        nextPayCutDate = nextPayCutDate.add(1, "month");
      }
      const nextBatchGeneration = nextPayCutDate.add(1, "day");
      return nextBatchGeneration.format("DD/MM/YYYY");
    },

    formattedBatchAnticipationDate(): string {
      return (this as any).item.batchAnticipationDate
        ? dayjs((this as any).item.batchAnticipationDate).format("DD/MM/YYYY")
        : "-";
    },

    minBatchAnticipationDate(): string {
      let date = dayjs();

      if (date.hour() >= 20) {
        date = date.add(1, "day");
      }

      return date.format("YYYY-MM-DD");
    },

    maxBatchAnticipationDate(): string {
      const payCutDay = Number((this as any).item.partnership.payCutDay);
      let nextPayCutDate = dayjs().set("date", payCutDay);
      if (
        nextPayCutDate.isBefore(dayjs(), "date") ||
        nextPayCutDate.isSame(dayjs(), "date")
      ) {
        nextPayCutDate = nextPayCutDate.add(1, "month");
      }
      return nextPayCutDate.format("YYYY-MM-DD");
    },

    disableBatchAnticipation(): boolean {
      return (
        (this as any).hasAlreadyAnticipatedBatch ||
        (this as any).hasAlreadyGeneratedBatchAutomatically
      );
    }
  }
})
export default class CompanyManageModal extends Vue {
  /** Computed property. Do NOT edit */
  canManagePartnershipParameters!: boolean;

  @Prop() show!: boolean;
  @Prop() readonly item!: Omit<Company, "contract">;
  @Prop() readonly observations!: CompanyObservation[];

  getErrorMessageForLandlineOrMobilePhoneValidation =
    getErrorMessageForLandlineOrMobilePhoneValidation;
  service: CompanyService;
  eletronicSignatureService: EletronicSignatureService;
  creditPolicyService: CreditPolicyService;
  marginBaseService: MarginBaseService;
  loanService: LoanService;
  groupsList: Array<Group>;
  loadingProducts = true;
  productList: Array<Products>;
  originalProducts: Array<Products>;
  groupsPage: number;
  groupsLimit: number;
  isApplyToGroup = false;
  states = municipios.estados;
  cities: string[] = [];
  segments = Segments;
  creditPolicies: Array<CreditPolicyItem> = [];
  creditPoliciesInSegment: Array<CreditPolicyItem> = [];
  defaultCreditPolicy: Partial<CreditPolicy> & { computedName: string } = {
    id: 0,
    computedName: "Padrão"
  };
  formatCNPJ = formatCNPJ;
  companyDocuments: CompanyDocument[];
  isLoadingDocuments: boolean = false;
  isLoading: boolean = false;
  isActive: boolean = false;
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
  statusHistory: StatusHistory[];
  isLoadingBatchAnticipationChecks: boolean = false;
  anticipateBatch: boolean = false;
  hasAlreadyAnticipatedBatch: boolean = false;
  hasAlreadyGeneratedBatchAutomatically: boolean = false;
  formattedLastBatchAnticipationDate: string = "";
  formattedAutomaticBatchGenerationDate: string = "";
  headersOfProducts: Array<DataTableHeader> = [
    { text: "Produto", value: "typeId" },
    { text: "Status", value: "isActive" },
    { text: "Bancarizadora", value: "bankingId" },
    { text: "Administradora", value: "adminId" },
    { text: "Fundo", value: "fundId" },
    { text: "% TAC", value: "tacPercentage" },
    { text: "Limite TAC", value: "tacLimit" },
    { text: "Carência", value: "gracePeriod" },
    { text: "Limite contrato", value: "requestedAmountLimit" },
    {
      text: "Máx. Margem",
      value: "maxMarginPercentage"
    },
    { text: "Limite de contratos", value: "maxContractsActivatedId" },
    // { text: "Usar Biometria", value: "useBiometry" }
  ];
  productStatusOptions = [
    { text: "Ativo", value: true },
    { text: "Inativo", value: false }
  ];
  products = Object.values(ProductsTypeEnum).map((product) => ({
    name: product.description,
    id: product.id
  }));
  bankings = Object.values(BankingEnum).map((banking) => ({
    name: banking.description,
    id: banking.id
  }));

  maxContractsActivateds = Object.values(MaxContractsActivatedEnum).map(
    (maxContractsActivated) => ({
      name: maxContractsActivated.description,
      id: maxContractsActivated.id
    })
  );

  administrators = Object.values(AdministratorEnum).map((admin) => ({
    name: admin.description,
    id: admin.id
  }));
  funds = Object.values(FundsEnum).map((fund) => ({
    name: fund.name,
    cnpj: fund.cnpj,
    id: fund.id
  }));
  allowGracePeriodForExpirationDay =
    Number(this.item.partnership.gracePeriodForExpirationDay) > 0;
  originalPayCutDay = this.item.partnership.payCutDay;
  currentDay = dayjs().get("date");

  consignetPartnerships: ConsignetPartnership[];

  constructor() {
    super();

    this.service = CompanyService.getInstance();
    this.eletronicSignatureService = EletronicSignatureService.getInstance();
    this.creditPolicyService = CreditPolicyService.getInstance();
    this.marginBaseService = MarginBaseService.getInstance();
    this.loanService = LoanService.getInstance();
    this.groupsList = [];
    this.groupsPage = 1;
    this.groupsLimit = 100000;
    this.companyDocuments = [];
    this.statusHistory = [];
    this.consignetPartnerships = [];
    if (!this.isAdminGooroo) {
      this.segments = SegmentsAvailableForSelfRegister;
    }
  }

  async mounted(): Promise<void> {
    this.runPartnershipChecks();
    this.loadCities();
    this.fetchMoreGroups();
    this.fetchCreditPolicies();
    this.isLoading = false;
    this.loadDocuments();
    this.loadActivationSwitchLogic();
    this.getCompanyStatusHistory();
    this.findConsignetPartnerships(this.item.useConsignetPartnership);
    this.fetchProductsByCompany(this.item.id);
  }

  getProductTypeName(typeId) {
    const product = this.products.find((p) => p.id === typeId);
    return product.name;
  }

  getProductStatusName(statusId: boolean): string {
    return this.productStatusOptions.find((option) => option.value === statusId)
      ?.text;
  }

  getBankingName(bankingId) {
    const banking = this.bankings.find((b) => b.id === bankingId);
    return banking.name;
  }

  getAdminstratorsName(adminId) {
    const admin = this.administrators.find((a) => a.id === adminId);
    return admin.name;
  }

  getMaxContracts(maxContractsActivatedId) {
    const maxContractsActivated = this.maxContractsActivateds.find(
      (a) => a.id === maxContractsActivatedId
    );
    return maxContractsActivated.name;
  }

  getFundName(fundId) {
    const fund = this.funds.find((f) => f.id === fundId);
    return `${fund.name} - ${formatCNPJ(fund.cnpj)}`;
  }

  async runPartnershipChecks(): Promise<void> {
    if (this.canManagePartnershipParameters) {
      this.isLoadingBatchAnticipationChecks = true;

      this.anticipateBatch = false;

      if (this.item.batchAnticipationDate) {
        const batchWasAnticipatedInAPreviousMonth = dayjs(
          this.item.batchAnticipationDate
        ).isBefore(dayjs().startOf("month"));
        const batchWasAnticipatedInCurrentMonth =
          dayjs(this.item.batchAnticipationDate).isSame(dayjs(), "month") &&
          (dayjs(this.item.batchAnticipationDate).isBefore(dayjs(), "date") ||
            dayjs(this.item.batchAnticipationDate).isSame(dayjs(), "date"));

        if (batchWasAnticipatedInAPreviousMonth) {
          // Clear batch anticipation date because it's not valid anymore
          this.item.batchAnticipationDate = "";
        } else if (batchWasAnticipatedInCurrentMonth) {
          // Cannot anticipate a batch twice in the same month
          this.formattedLastBatchAnticipationDate = dayjs(
            this.item.batchAnticipationDate
          ).format("DD/MM/YYYY");
          this.item.batchAnticipationDate = "";
          this.hasAlreadyAnticipatedBatch = true;

          this.isLoadingBatchAnticipationChecks = false;
        }
      }

      const [currentMonthBatchesError, currentMonthBatches] =
        await this.loanService.listBatches({
          page: 1,
          limit: 1,
          sort: "generationDate:ASC",
          companyId: this.item.id,
          startGenerationDate: dayjs().startOf("month").toDate(),
          endGenerationDate: dayjs().endOf("month").toDate()
        });

      if (currentMonthBatchesError) {
        this.$notify({
          type: "error",
          text: `Não foi possível buscar os dados para permitir antecipação de lote. Erro: ${getErrorMessageFromApiError(
            currentMonthBatchesError
          )}`
        });

        this.isLoadingBatchAnticipationChecks = false;

        return;
      }

      const batchWasGeneratedInCurrentMonth =
        currentMonthBatches!.items.length > 0;

      if (batchWasGeneratedInCurrentMonth) {
        // Cannot anticipate a batch if it was already generated automatically in the current month
        this.hasAlreadyGeneratedBatchAutomatically = true;
        this.formattedAutomaticBatchGenerationDate = dayjs(
          currentMonthBatches!.items[0].generationDate
        ).format("DD/MM/YYYY");
        this.item.batchAnticipationDate = "";

        this.isLoadingBatchAnticipationChecks = false;
      }

      if (this.item.batchAnticipationDate) this.anticipateBatch = true;

      this.isLoadingBatchAnticipationChecks = false;
    }
  }

  getCompanyStatusHistory = async (): Promise<void> => {
    this.statusHistory.length = 0;
    const [error, statusHistory] = await this.service.getCompanyStatusHistory(
      this.item.id
    );

    if (!error && statusHistory) {
      for (const status of statusHistory) {
        this.statusHistory.push(status);
      }
      this.statusHistory = statusHistory;
    }
  };

  fetchCreditPolicies = async (): Promise<void> => {
    const [creditPoliciesError, creditPolicies] =
      await this.creditPolicyService.listCreditPolicies();

    if (!creditPoliciesError) {
      this.creditPolicies.splice(0, this.creditPolicies.length);
      for (const cp of creditPolicies!) {
        this.creditPolicies.push({
          ...cp,
          computedName: cp.identifier
        });
      }
      this.filterCreditPoliciesInSegment(this.item.segmentId);
    }
  };

  filterCreditPoliciesInSegment = (segmentId: number): void => {
    const creditPoliciesInSegment = this.creditPolicies.filter(
      (cp) => cp.segment?.id === segmentId
    );
    this.creditPoliciesInSegment.splice(0, this.creditPoliciesInSegment.length);
    this.creditPoliciesInSegment.push(
      this.defaultCreditPolicy as CreditPolicyItem
    );
    for (const cp of creditPoliciesInSegment)
      this.creditPoliciesInSegment.push(cp);

    // Select default credit policy if company doesn't have one
    if (!this.item.creditPolicyId)
      this.item.creditPolicyId = this.defaultCreditPolicy.id;
  };

  loadDocuments = async (): Promise<void> => {
    this.isLoadingDocuments = true;
    this.companyDocuments.length = 0;
    if (this.item && this.item.id) {
      const [companyDocumentsError, companyDocuments] =
        await this.service.listCompanyDocuments(this.item.id);

      if (companyDocuments && !companyDocumentsError) {
        this.companyDocuments.length = 0;
        companyDocuments?.forEach((companyDocument) => {
          this.companyDocuments.push(companyDocument);
        });
      }

      if (this.item.clicksignDocumentKey) {
        const [clickSignDocumentError, clickSignDocument] =
          await this.eletronicSignatureService.showDocument(
            this.item.clicksignDocumentKey
          );

        if (clickSignDocument && !clickSignDocumentError) {
          var companyDocument = {
            url: clickSignDocument.download_url,
            type: {
              description: "Contrato Gooroo",
              id: 0,
              name: "CONTRATO_GOOROO",
              created_at: new Date(),
              updated_at: new Date()
            } as CompanyDocumentType,
            typeId: 0,
            sentDate: this.item.clicksignWebhookEventDate || new Date(),
            id: 0,
            companyId: this.item.id,
            company: this.item,
            created_at: new Date(),
            updated_at: new Date()
          } as CompanyDocument;
          this.companyDocuments.push(companyDocument);
        }
      }
    }
    this.isLoadingDocuments = false;
  };

  loadActivationSwitchLogic(): void {
    this.isActive = this.item.status.name === "ATIVA";
  }

  loadCities(): void {
    if (this.item.state) {
      this.cities = municipios.cidadePorEstado(this.item.state);
    } else {
      this.cities = [];
    }
    if (this.item.city && !this.cities.includes(this.item.city)) {
      this.item.city = "";
    }
  }

  async switchActiveInactive(shouldBeActive: boolean): Promise<void> {
    if (shouldBeActive) {
      this.$emit("activate");
    } else {
      this.$emit("block");
    }
    this.isLoading = true;
  }

  get formTitle(): string {
    return this.item.index === null ? "Nova empresa" : "Editar empresa";
  }

  async findConsignetPartnerships(
    useConsignetPartnership?: boolean | null
  ): Promise<void> {
    if (!useConsignetPartnership) {
      this.item.useAutomaticEndorsement = false;
      return;
    }

    const [error, consignetData] =
      await this.marginBaseService.findConsignetPartnerships();

    if (!error && consignetData?.length) {
      this.consignetPartnerships = consignetData!;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async fetchMoreGroups(): Promise<void> {
    const [error, groupsData] = await this.service.listGroups({
      page: this.groupsPage,
      limit: this.groupsLimit,
      sort: "name:ASC",
      groupName: "",
      companyNameOrCnpj: "",
      adminEmail: "",
      adminPhone: ""
    });
    const newGroups = groupsData.items;
    if (!error) {
      this.groupsList = [...this.groupsList, ...newGroups];
      this.groupsPage += 1;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async fetchProductsByCompany(companyId: number): Promise<void> {
    this.loadingProducts = true;
    const [error, productData] = await this.service.findProductsByCompanies([
      companyId
    ]);

    if (!error) {
      this.productList = (productData[companyId] || []) as Products[];

      this.productList = this.productList.map((product) => ({
        ...product,
        maxContractsActivatedId:
          product.maxContractsActivatedId ||
          MaxContractsActivatedEnum.UNLIMITED.id,
        useBiometry: product.useBiometry || false
      }));

      this.originalProducts = [...this.productList];
      this.loadingProducts = false;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  displayNotAutomaticEndorsementWarning(): void {
    this.$notify({
      type: "warning",
      text: "Atenção! Ao desativar a averbação automática, será necessária uma ação manual de averbação para as propostas seguirem na esteira.",
      duration: 10000
    });
  }

  get isAdminGooroo() {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }

  get isPartner() {
    return ["PARTNER_MASTER", "PARTNER"].includes(
      this.$store.getters["auth/authenticatedUser"]?.type
    );
  }

  get loggedPartnerId() {
    return this.$store.getters["auth/loggedPartnerId"];
  }

  get hasSaveAuthorization() {
    if (this.hasPermissions(["ATUALIZAR_EMPRESA"])) {
      if (!this.isPartner || !this.item) {
        return true;
      }

      if (this.loggedPartnerId === this.item.group?.partnerId) {
        return true;
      }

      return false;
    }
    return false;
  }

  toggleUseCustomProducts() {
    if (!this.item.useCustomProducts) {
      // When toggling off, reset the products to their "original" state
      this.productList = JSON.parse(JSON.stringify(this.originalProducts));
    }
  }

  toggleApplyToTheGroup() {
    this.isApplyToGroup = !this.isApplyToGroup;
  }

  async saveChanges() {
    if (this.isApplyToGroup) {
      const userResponse = confirm(
        "Você está prestes a aplicar todos esses produtos selecionados para todas as empresas do grupo. Deseja continuar?"
      );
      if (userResponse && this.item.id && this.item.groupId) {
        const [error] = await this.service.updateProductsByCompany({
          useCustomProducts: this.item.useCustomProducts,
          companyId: this.item.id,
          groupId: this.item.groupId,
          applyOnGroup: true,
          productList: this.productList
        });
        if (!error) {
          this.$notify({
            type: "success",
            text: "Grupo Atualizado com sucesso."
          });
        } else {
          this.$notify({
            type: "error",
            text: getErrorMessageFromApiError(error)
          });
        }
        await this.fetchProductsByCompany(this.item.id);
      }
    } else {
      const [error] = await this.service.updateProductsByCompany({
        useCustomProducts: this.item.useCustomProducts,
        companyId: this.item.id,
        applyOnGroup: false,
        productList: this.productList
      });
      if (!error) {
        this.$notify({
          type: "success",
          text: "Empresa Atualizada com sucesso."
        });
      } else {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      }
      await this.fetchProductsByCompany(this.item.id);
    }
  }
}
