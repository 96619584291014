
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Loan from "@/types/loan";
import LoanStatus, { LoanStatusEnum } from "@/types/loan-status";
import formatCurrency from "@/utils/formatCurrency";
import { format as formatCPF } from "@/utils/cpf";
import dayjs from "dayjs";
import DatePicker from "@/components/DatePicker.vue";
import { BankingEnum } from "../../types/BankingEnum";
import { FundsEnum } from "../../types/FundsEnum";
import LoanService from "@/services/loan-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";

@Component({
  components: { ValidationObserver, DatePicker }
})
export default class LoanUpdateStatusModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;
  isLoading: boolean = false;
  observation: string = "";
  status: string | null = null;
  paidDate: string | null = null;
  LoanStatusEnum = LoanStatusEnum;
  loadingAdditionalLoanData: boolean = true;
  loanService: LoanService;
  columns: string[] = [
    "id",
    "total",
    "creditAmount",
    "startedSimulationDate",
    "bankingId"
  ];

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
  }

  handleUpdateStatus(): void {
    this.isLoading = true;
    this.$emit("input", {
      id: this.item?.id,
      status: this.status,
      observation: this.observation || "",
      paidDate: this.paidDate || ""
    });
    this.status = null;
    this.observation = "";
    this.paidDate = "";
  }

  async mounted(): Promise<void> {
    await this.fetchLoanAdditionalData();
  }

  async fetchLoanAdditionalData(): Promise<void> {
    const [error, loanAdditionalData] = await this.loanService.listLoans({
      loanId: this.item.id,
      page: 1,
      limit: 1,
      loadDocuments: false,
      loadStatusHistory: false,
      loadInstallments: false,
      loadRemittance: false,
      loadRefinancing: false,
      columns: this.columns.join(",")
    });

    if (error || !loanAdditionalData) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }

    if (loanAdditionalData.items.length) {
      const additionalData = loanAdditionalData.items[0];
      const keysToUpdate = [...this.columns];

      keysToUpdate.forEach((key) => {
        if (additionalData[key] !== undefined) {
          this.item[key] = additionalData[key];
        }
      });
    }

    this.loadingAdditionalLoanData = false;
  }

  get possibleStatus(): Array<LoanStatus> {
    return this.item?.status?.possibleStatus || [];
  }

  get allowedPossibleStatus(): Array<LoanStatus> {
    return this.possibleStatus.filter(
      (loanStatus) =>
        !this.notAllowedPossibleStatus.find(
          (notAllowedLoanStatus) => notAllowedLoanStatus.id === loanStatus.id
        )
    );
  }

  get notAllowedPossibleStatus(): Array<LoanStatus> {
    let notAllowed: LoanStatus[] = [
      LoanStatusEnum.PAID_OFF,
      LoanStatusEnum.EXCLUDED_BY_BORROWER_EXCLUSION
    ];

    if (
      this.item.bankingId === BankingEnum.LECCA.id &&
      this.item.fundId === FundsEnum.SANTA_BARBARA.id
    ) {
      // Lecca proposals are automatically set to Payment In Process by the system
      notAllowed.push(LoanStatusEnum.PAYMENT_IN_PROCESS);
    } else {
      // Payment inclusion flow is for Lecca proposals only
      notAllowed.push(LoanStatusEnum.PAYMENT_INCLUSION);
    }

    return notAllowed;
  }

  get timeRangesForBankingRelease(): {
    [key: number]: { start: () => dayjs.Dayjs; end: () => dayjs.Dayjs };
  } {
    return {
      [BankingEnum.BRP.id]: {
        start: () => dayjs().set("hour", 0).set("minute", 0).set("second", 0),
        end: () => dayjs().set("hour", 14).set("minute", 25).set("second", 0)
      }
    };
  }

  get hasTimeLimitForBankingRelease(): boolean {
    return !!this.timeRangesForBankingRelease[this.item.bankingId];
  }

  get formattedStartTimeForBankingRelease(): string {
    const timeRange = this.timeRangesForBankingRelease[this.item.bankingId];
    if (!timeRange) return "";
    return timeRange.start().format("HH:mm");
  }

  get formattedEndTimeForBankingRelease(): string {
    const timeRange = this.timeRangesForBankingRelease[this.item.bankingId];
    if (!timeRange) return "";
    return timeRange.end().format("HH:mm");
  }

  get total(): string {
    return formatCurrency(this.item?.total || 0);
  }

  get creditAmount(): string {
    return formatCurrency(this.item?.creditAmount || 0);
  }

  get startedSimulationDate(): string {
    return this.formatDate(this.item?.startedSimulationDate);
  }

  get borrowerCpf(): string {
    return formatCPF(this.item?.borrowerCpf);
  }

  formatDate(date?: Date | null | undefined): string {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  }
}
