
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Loan from "@/types/loan";
import formatCurrency from "@/utils/formatCurrency";
import { format as formatCPF } from "@/utils/cpf";
import { BorrowerSigningMethod } from "../../types/borrower";
import dayjs from "dayjs";
import LoanService from "@/services/loan-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";

@Component({
  components: { ValidationObserver }
})
export default class LoanUpdateSigningMethod extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;
  isLoading: boolean = false;
  loanService: LoanService;
  columns: string[] = ["id", "total", "creditAmount", "startedSimulationDate"];
  signingMethod: string | null = null;
  signingMethods = [
    { text: "SMS", value: BorrowerSigningMethod.SMS },
    { text: "WhatsApp", value: BorrowerSigningMethod.WHATSAPP }
  ];

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
  }

  async created() {
    this.isLoading = true;
    await this.fetchLoanAdditionalData();
  }

  async fetchLoanAdditionalData(): Promise<void> {
    this.isLoading = true;

    const [error, loanAdditionalData] = await this.loanService.listLoans({
      loanId: this.item.id,
      page: 1,
      limit: 1,
      loadDocuments: false,
      loadStatusHistory: false,
      loadInstallments: false,
      loadRemittance: false,
      loadRefinancing: false,
      columns: this.columns.join(",")
    });

    if (error || !loanAdditionalData) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }

    if (loanAdditionalData.items.length) {
      const [additionalData] = loanAdditionalData.items;
      const keysToUpdate = [...this.columns];

      keysToUpdate.forEach((key) => {
        if (additionalData[key] !== undefined) {
          this.item[key] = additionalData[key];
        }
      });
    }

    this.isLoading = false;
  }

  handleUpdateSigningMethod(): void {
    this.isLoading = true;
    this.$emit("input", {
      id: this.item.id,
      signingMethod: this.signingMethod
    });
    this.signingMethod = null;
  }

  get total(): string {
    return formatCurrency(this.item?.total || 0);
  }

  get creditAmount(): string {
    return formatCurrency(this.item?.creditAmount || 0);
  }

  get startedSimulationDate(): string {
    return this.formatDate(this.item?.startedSimulationDate);
  }

  get borrowerCpf(): string {
    return formatCPF(this.item?.borrowerCpf);
  }

  formatDate(date?: Date | null | undefined): string {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  }
}
