
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Loan from "@/types/loan";
import CompanyService, {
  ListCompaniesResponse
} from "@/services/company-service";
import formatCurrency from "@/utils/formatCurrency";
import { format as formatCPF } from "@/utils/cpf";
import { format as formatCNPJ } from "@/utils/cnpj";
import dayjs from "dayjs";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import LoanService from "@/services/loan-service";

@Component({
  components: { ValidationObserver }
})
export default class LoanUpdateCompanyModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;
  isLoading: boolean = false;
  selectedCompany: number | null = null;
  companies: ListCompaniesResponse = {
    items: [],
    total: 0
  };
  companyService: CompanyService;
  loanService: LoanService;
  columns: string[] = ["id", "total", "creditAmount", "startedSimulationDate"];
  formatCNPJ = formatCNPJ;
  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.loanService = LoanService.getInstance();
  }

  async created() {
    this.isLoading = true;
    await this.fetchLoanAdditionalData();
    const [getCompanyError, getCompanyData] =
      await this.companyService.getCompany(this.item.companyId);

    if (getCompanyError) {
      this.$notify({
        title: "Erro ao obter dados de empresa",
        type: "error",
        text: "Empresas não encontradas."
      });
    }

    if (getCompanyData) {
      const [error, companies] = await this.companyService.listCompanies({
        page: 1,
        limit: -1,
        sort: "name:ASC",
        groupId: getCompanyData.groupId
      });

      this.companies = companies;
      this.isLoading = false;
    }
  }

  async fetchLoanAdditionalData(): Promise<void> {
    const [error, loanAdditionalData] = await this.loanService.listLoans({
      loanId: this.item.id,
      page: 1,
      limit: 1,
      loadDocuments: false,
      loadStatusHistory: false,
      loadInstallments: false,
      loadRemittance: false,
      loadRefinancing: false,
      columns: this.columns.join(",")
    });

    if (error || !loanAdditionalData) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }

    if (loanAdditionalData.items.length) {
      const [additionalData] = loanAdditionalData.items;
      const keysToUpdate = [...this.columns];

      keysToUpdate.forEach((key) => {
        if (additionalData[key] !== undefined) {
          this.item[key] = additionalData[key];
        }
      });
    }
  }

  handleUpdateCompany(): void {
    if (this.item.companyId == this.selectedCompany) {
      this.$notify({
        title: "Erro ao transferir contrato",
        type: "error",
        text: "Contrato não pode ser transferido para a empresa dententora do contrato."
      });
    } else {
      this.$emit("input", {
        id: this.item?.id,
        companyId: this.selectedCompany
      });
    }
    this.isLoading = false;
    this.selectedCompany = null;
  }

  get total(): string {
    return formatCurrency(this.item?.total || 0);
  }

  get creditAmount(): string {
    return formatCurrency(this.item?.creditAmount || 0);
  }

  get startedSimulationDate(): string {
    return this.formatDate(this.item?.startedSimulationDate);
  }

  get borrowerCpf(): string {
    return formatCPF(this.item?.borrowerCpf);
  }

  formatDate(date?: Date | null | undefined): string {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  }
}
