import {
  alpha_dash,
  alpha_num,
  alpha_spaces,
  between,
  confirmed,
  digits,
  email,
  length,
  max,
  max_value,
  min,
  min_value,
  regex,
  required
} from "vee-validate/dist/rules";

import { validate as validateCNPJ } from "@/utils/cnpj";
import { validate as validateCPF } from "@/utils/cpf";
import { currentOrFutureDate } from "@/utils/currentOrFutureDate";
import { validateDateIn_DD_MM_YYYY } from "@/utils/dateIn_DD_MM_YYYY";
import { validateDayInInterval } from "@/utils/dayInInterval";
import { validate as validateUF } from "@/utils/uf";
import { extend } from "vee-validate";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Boleto from "boleto.js";
import dayjs from "dayjs";
import isValidDebtPurchaseDueDate from "../utils/isValidDebtPurchaseDueDate";
import { isAfterMinimumDate } from "../utils/isAfterMinimumDate";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";
import isValidBranch from "../utils/isValidBranch";
import isValidCheckingAccount from "../utils/isValidCheckingAccount";
import { CreditPolicyRange } from "../services/safe-boarding-service";
import getErrorMessageForLandlineOrMobilePhoneValidation from "@/utils/getErrorMessageForLandlineOrMobilePhoneValidation";
import {
  INVALID_PASSWORD_DEFAULT_MESSAGE,
  isValidPassword
} from "@/utils/isValidPassword";
import formatCurrency from "../utils/formatCurrency";
import { currentOrPastDate } from "@/utils/currentOrPastDate";
import { isAfterOrEqualsDate } from "@/utils/isAfterOrEqualsDate";

extend("required", {
  ...required,
  message: "Este campo é obrigatório"
});

extend("max", {
  ...max,
  message: "Este campo deve ter {length} caracteres ou menos."
});

extend("min", {
  ...min,
  message: "Este campo deve ter {length} caracteres ou mais."
});

extend("min_value", {
  ...min_value,
  message: (fieldName, { min }) => {
    return `Este campo deve ter um valor mínimo de ${min}.`;
  }
});

extend("min_currency", {
  ...min_value,
  message: (fieldName, { min }) => {
    return `Este campo deve ter um valor mínimo de ${formatCurrency(min)}.`;
  }
});

extend("min_percentage_value", {
  ...min_value,
  message: "Este campo deve ter um valor mínimo de {min}%."
});

extend("max_value", {
  ...max_value,
  message: (fieldName, { max }) => {
    return `Este campo deve ter um valor máximo de ${max}.`;
  }
});

extend("max_currency", {
  ...max_value,
  message: (fieldName, { max }) => {
    return `Este campo deve ter um valor máximo de ${formatCurrency(max)}.`;
  }
});

extend("max_percentage_value", {
  ...max_value,
  message: "Este campo deve ter um valor máximo de {max}%."
});

extend("length", {
  ...length,
  message: "Este campo deve ter {length} caracteres."
});

extend("digits", {
  ...digits,
  message: "Este campo deve ter {length} dígitos."
});

extend("email", {
  ...email,
  message: "Este campo deve ser um email válido."
});

extend("confirmed", {
  ...confirmed,
  message: "As senhas não correspondem."
});

extend("between", {
  ...between,
  message: "Digite um valor entre {min} e {max}."
});

extend("alpha_dash", {
  ...alpha_dash,
  message: "Digite apenas letras, números, hífens e sublinha."
});

extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Digite apenas letras e espaços."
});

extend("alpha", {
  ...alpha_num,
  message: "Digite apenas letras e números."
});

extend("alphanumeric_spaces", {
  validate: (value, args) => /^[0-9A-Z\s]*$/i.test(value),
  message: "Digite apenas letras, números e espaços."
});

extend("regex", {
  ...regex,
  message: "Digite um valor válido."
});

extend("password", {
  validate: (value, args) => isValidPassword(value),
  message: INVALID_PASSWORD_DEFAULT_MESSAGE
});

extend("date_dd_mm_aaaa", {
  validate: (value, args) => (value ? validateDateIn_DD_MM_YYYY(value) : true),
  message: "Digite uma data no formato dd/mm/aaaa"
});

extend("min_array", {
  params: ["minLength"],
  validate: (value, { minLength }: any) =>
    value ? value.length >= minLength : false,
  message: "Digite uma data no formato dd/mm/aaaa"
});

extend("min_date", {
  params: ["minDate"],
  validate: (value, { minDate }: any) => isAfterMinimumDate({ value, minDate }),
  message: "Data de nascimento não permitida"
});

extend("min_initial_and_final_date", {
  params: ["minDate"],
  validate: (value, { minDate }: any) => {
    const formattedMinDate = dayjs(minDate).format("DD/MM/YYYY");

    return isAfterOrEqualsDate({
      value,
      minDate: formattedMinDate
    });
  },
  message: "Data Final tem que ser maior ou igual que a Inicial"
});

extend("dia_1_a_30", {
  validate: (value, args) =>
    validateDayInInterval({
      day: value,
      min: 1,
      max: 30
    }),
  message: "Digite um dia entre 1 e 30"
});

extend("dia_1_a_27", {
  validate: (value, args) =>
    validateDayInInterval({
      day: value,
      min: 1,
      max: 27
    }),
  message: "Digite um dia entre 1 e 27"
});

extend("isOfLegalAge", {
  validate: (value) => {
    const today = dayjs();
    const adultDate = today.subtract(18, "year");

    const birthDate = dayjs(value, "DD/MM/YYYY");

    return birthDate.isBefore(adultDate);
  },
  message: "Data de nascimento não permitida"
});

extend("cpf", {
  validate: (value, args) => validateCPF(value),
  message: "CPF inválido"
});

extend("cnpj", {
  validate: (value, args) => validateCNPJ(value),
  message: "CNPJ inválido"
});

extend("uf", {
  validate: (value, args) => validateUF(value),
  message: "UF inválida"
});

extend("current_or_future_date", {
  validate: (value, args) => (value ? currentOrFutureDate(value) : true),
  message: "Digite uma data atual ou futura."
});

extend("current_or_past_date", {
  validate: (value, args) => (value ? currentOrPastDate(value) : true),
  message: "Digite uma data atual ou anterior."
});

extend("current_date_plus_5_days", {
  validate: (value, args) => currentOrFutureDate(value, 5),
  message:
    "Digite uma data com pelo menos 5 dias de diferença para a data atual."
});

extend("is_valid_branch", {
  validate: (value, args) => isValidBranch(value),
  message: "Digite corretamente os dados da agência."
});

extend("is_valid_checking_account", {
  validate: (value, args) => isValidCheckingAccount(value),
  message: "Digite corretamente os dados da conta."
});

extend("fullname", {
  validate: (value, args) =>
    value &&
    value.split(" ").length >= 2 &&
    /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ\s'-]+$/.test(value),
  message:
    "Você deve informar um nome completo válido. Sem caracteres especiais."
});

extend("fullname_with_numbers", {
  validate: (value, args) =>
    value &&
    value.split(" ").length >= 2 &&
    /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ\s'\d-]+$/.test(value),
  message: "Você deve informar um nome completo válido."
});

extend("mobile_phone", {
  validate: (value) => {
    return !getErrorMessageForMobilePhoneValidation(value);
  },
  message: "Telefone celular inválido."
});

extend("landline_or_mobile_phone", {
  validate: (value) => {
    return !getErrorMessageForLandlineOrMobilePhoneValidation(value);
  },
  message: "Telefone inválido."
});

extend("allowed_admin_gooroo_email", {
  validate: (value, args) =>
    value.endsWith("@finnet.com.br") || value.endsWith("@gooroocredito.com.br"),
  message: "Este e-mail não é aceito."
});

extend("is_cut_day_in_min_10_day_range_from_expiration_day", {
  params: ["expirationDay"],
  validate: (cutDay, { expirationDay }: any) => {
    const maxDayAllowedInMonth = 27;

    if (!expirationDay || !cutDay) {
      return true;
    }

    const parsedCutDay = Number(cutDay);
    const parsedExpiratioNDay = Number(expirationDay);

    const minimumDifferenceInDays = 10;

    if (parsedCutDay < parsedExpiratioNDay) {
      return (
        maxDayAllowedInMonth - parsedExpiratioNDay + parsedCutDay >=
        minimumDifferenceInDays
      );
    } else {
      return (
        Math.abs(parsedExpiratioNDay - parsedCutDay) >= minimumDifferenceInDays
      );
    }
  },
  message:
    "A data de corte deve ser no mínimo 10 dias maior que a data de vencimento do boleto de repasse."
});

extend("valid_digitable_line", {
  validate: (value) => {
    try {
      const bankSlip = new Boleto(value);
      const bankSlipDueDate = dayjs(bankSlip.expirationDate()).startOf("day");

      if (!isValidDebtPurchaseDueDate(bankSlipDueDate)) {
        return "O boleto deve ter a data de vencimento igual ou posterior à data de hoje.";
      }

      return true;
    } catch (e) {
      return false;
    }
  },
  message: "A linha digitável informada é inválida."
});

extend("compare_installment_value", {
  validate: (value: number, params: Record<string, any>) => {
    const installmentValue: number = params.installmentValue;

    if (Number(value) > Number(installmentValue)) {
      return false;
    }
    return true;
  },
  params: ["installmentValue"],
  computesRequired: true,
  message: (fieldName, { installmentValue }) => {
    return `O valor do boleto deve ser menor ou igual ao valor da parcela:  ${installmentValue}.`;
  }
});

extend("compare_installment_present_value", {
  validate: (value: number, params: Record<string, any>) => {
    const installmentValue: number = params.installmentValue;

    if (Number(value) < Number(installmentValue)) {
      return false;
    }
    return true;
  },
  params: ["installmentValue"],
  computesRequired: true,
  message: (fieldName, { installmentValue }) => {
    return `O valor do boleto deve ser maior ou igual ao valor da parcela:  ${installmentValue}.`;
  }
});
