export default interface LoanStatus {
  id: number;
  name: string;
  description: string;
  possibleStatus?: LoanStatus[] | null;
}

const inSimulation: LoanStatus = {} as LoanStatus;
inSimulation.id = 1;
inSimulation.name = "EM_SIMULACAO";
inSimulation.description = "Em simulação";

const pendingSignature: LoanStatus = {} as LoanStatus;
pendingSignature.id = 2;
pendingSignature.name = "PENDENTE_ASSINATURA";
pendingSignature.description = "Aguardando assinatura de contrato";

const pendingFinancialCompanySignatureBatch: LoanStatus = {} as LoanStatus;
pendingFinancialCompanySignatureBatch.id = 21;
pendingFinancialCompanySignatureBatch.name = "PENDENTE_LOTE_FINANCEIRA";
pendingFinancialCompanySignatureBatch.description =
  "Aguardando lote da financeira";

const pendingFinancialCompanySignature: LoanStatus = {} as LoanStatus;
pendingFinancialCompanySignature.id = 20;
pendingFinancialCompanySignature.name = "PENDENTE_ASSINATURA_FINANCEIRA";
pendingFinancialCompanySignature.description =
  "Aguardando assinatura da financeira";

const refusedSignature: LoanStatus = {} as LoanStatus;
refusedSignature.id = 3;
refusedSignature.name = "RECUSADO_ASSINATURA";
refusedSignature.description = "Assinatura de contrato recusada";

const pendingEndorsement: LoanStatus = {} as LoanStatus;
pendingEndorsement.id = 4;
pendingEndorsement.name = "PENDENTE_AVERBACAO";
pendingEndorsement.description = "Aguardando autorização da empresa";

const refusedEndorsement: LoanStatus = {} as LoanStatus;
refusedEndorsement.id = 5;
refusedEndorsement.name = "RECUSADO_AVERBACAO";
refusedEndorsement.description = "Negado pela empresa";

const pendingDocumentValidation: LoanStatus = {} as LoanStatus;
pendingDocumentValidation.id = 6;
pendingDocumentValidation.name = "PENDENTE_VALIDACAO_DOCUMENTOS";
pendingDocumentValidation.description = "Aguardando validação de documentos";

const refusedDocumentValidation: LoanStatus = {} as LoanStatus;
refusedDocumentValidation.id = 7;
refusedDocumentValidation.name = "RECUSADO_VALIDACAO_DOCUMENTOS";
refusedDocumentValidation.description = "Validação de documentos reprovada";

const pendingExternalDocumentValidation: LoanStatus = {} as LoanStatus;
pendingExternalDocumentValidation.id = 11;
pendingExternalDocumentValidation.name =
  "PENDENTE_VALIDACAO_EXTERNA_DOCUMENTOS";
pendingExternalDocumentValidation.description =
  "Aguardando reenvio de documentos";

const refusedExternalDocumentValidation: LoanStatus = {} as LoanStatus;
refusedExternalDocumentValidation.id = 12;
refusedExternalDocumentValidation.name =
  "RECUSADO_VALIDACAO_EXTERNA_DOCUMENTOS";
refusedExternalDocumentValidation.description =
  "Reenvio de documentos recusado";

const approved: LoanStatus = {} as LoanStatus;
approved.id = 17;
approved.name = "APROVADO";
approved.description = "Aprovado";

const paymentInProcess: LoanStatus = {} as LoanStatus;
paymentInProcess.id = 8;
paymentInProcess.name = "PAGAMENTO_EM_ANDAMENTO";
paymentInProcess.description = "Pagamento em andamento";

const paid: LoanStatus = {} as LoanStatus;
paid.id = 9;
paid.name = "PAGO";
paid.description = "Pagamento efetuado";

const canceledByFinancialCompany: LoanStatus = {} as LoanStatus;
canceledByFinancialCompany.id = 10;
canceledByFinancialCompany.name = "CANCELADO_FINANCEIRA";
canceledByFinancialCompany.description = "Empréstimo cancelado pela financeira";

const expired: LoanStatus = {} as LoanStatus;
expired.id = 13;
expired.name = "EXPIRADO";
expired.description = "Contrato expirado";

const refusedPayment: LoanStatus = {} as LoanStatus;
refusedPayment.id = 14;
refusedPayment.name = "RECUSADO_PAGAMENTO";
refusedPayment.description = "Pagamento recusado";

const canceledByGooroo: LoanStatus = {} as LoanStatus;
canceledByGooroo.id = 15;
canceledByGooroo.name = "CANCELADO_GOOROO";
canceledByGooroo.description = "Empréstimo cancelado pelo Gooroo";

const canceledWhenEmployeeFired: LoanStatus = {} as LoanStatus;
canceledWhenEmployeeFired.id = 16;
canceledWhenEmployeeFired.name = "CANCELADO_FUNCIONARIO_DESLIGADO";
canceledWhenEmployeeFired.description =
  "Cancelado por desligamento do funcionário";

const paidOff: LoanStatus = {} as LoanStatus;
paidOff.id = 18;
paidOff.name = "LIQUIDADO";
paidOff.description = "Liquidado";

const thirdPartySimulation: LoanStatus = {} as LoanStatus;
thirdPartySimulation.id = 19;
thirdPartySimulation.name = "SIMULACAO_EMPRESTIMO_TERCEIRO";
thirdPartySimulation.description = "Simulação de empréstimo em atendimento";

const canceledByApi: LoanStatus = {} as LoanStatus;
canceledByApi.id = 24;
canceledByApi.name = "CANCELADO_POR_API";
canceledByApi.description = "Cancelado por API de parceiro";

const bankslipPaymentRefused: LoanStatus = {} as LoanStatus;
bankslipPaymentRefused.id = 25;
bankslipPaymentRefused.name = "PAGAMENTO_BOLETO_RECUSADO";
bankslipPaymentRefused.description = "Pagamento Boleto Recusado";

const paymentInclusion: LoanStatus = {} as LoanStatus;
paymentInclusion.id = 26;
paymentInclusion.name = "INCLUSAO_PAGAMENTO";
paymentInclusion.description = "Inclusão de pagamento";

const includedPayment: LoanStatus = {} as LoanStatus;
includedPayment.id = 27;
includedPayment.name = "PAGAMENTO_INCLUIDO";
includedPayment.description = "Pagamento incluído";

const resentPaymentInProcess: LoanStatus = {} as LoanStatus;
resentPaymentInProcess.id = 26;
resentPaymentInProcess.name = "PAGAMENTO_EM_ANDAMENTO_REENVIADO";
resentPaymentInProcess.description = "Pagamento em andamento reenviado";

const excludedByBorrowerExclusion: LoanStatus = {} as LoanStatus;
excludedByBorrowerExclusion.id = 29;
excludedByBorrowerExclusion.name = "EXCLUIDO_POR_EXCLUSAO_TOMADOR";
excludedByBorrowerExclusion.description = "Excluído por exclusão do tomador";

export const LoanStatusEnum = {
  IN_SIMULATION: inSimulation,
  PENDING_SIGNATURE: pendingSignature,
  PENDING_FINANCIAL_COMPANY_SIGNATURE_BATCH:
    pendingFinancialCompanySignatureBatch,
  PENDING_FINANCIAL_COMPANY_SIGNATURE: pendingFinancialCompanySignature,
  REFUSED_SIGNATURE: refusedSignature,
  PENDING_ENDORSEMENT: pendingEndorsement,
  REFUSED_ENDORSEMENT: refusedEndorsement,
  PENDING_DOCUMENT_VALIDATION: pendingDocumentValidation,
  REFUSED_DOCUMENT_VALIDATION: refusedDocumentValidation,
  PENDING_EXTERNAL_DOCUMENT_VALIDATION: pendingExternalDocumentValidation,
  REFUSED_EXTERNAL_DOCUMENT_VALIDATION: refusedExternalDocumentValidation,
  APPROVED: approved,
  PAYMENT_IN_PROCESS: paymentInProcess,
  REFUSED_PAYMENT: refusedPayment,
  PAID: paid,
  EXPIRED: expired,
  CANCELED_FIRED_EMPLOYEE: canceledWhenEmployeeFired,
  CANCELED_BY_FINANCIAL_COMPANY: canceledByFinancialCompany,
  CANCELED_BY_GOOROO: canceledByGooroo,
  PAID_OFF: paidOff,
  THIRD_PARTY_SIMULATION: thirdPartySimulation,
  CANCELED_BY_API: canceledByApi,
  BANKSLIP_PAYMENT_REFUSED: bankslipPaymentRefused,
  PAYMENT_INCLUSION: paymentInclusion,
  INCLUDED_PAYMENT: includedPayment,
  RESENT_PAYMENT_IN_PROCESS: resentPaymentInProcess,
  EXCLUDED_BY_BORROWER_EXCLUSION: excludedByBorrowerExclusion
};
